export const coaches = [
      {
        id: 1,
        name: "Alex Petropolis",
        description:
        "Alex is the Head Coach of the Citadel High School boys team, and has coached all the way from youth to university in his growing coaching career. He was a teammate of Andrew’s when they both played point guard for BNS.",
        img: "images/coaches/AlexPetro.png",
        alt: "alex_petropolis",
      },    
      {
        id: 2,
        name: "Brandon Brown",
        description:
        "Brandon is Atlantic Canada’s best skills trainer, being trusted for skills development by athletes like Nate Darling and Lindell Wiggington. He coached Andrew on the BNS National Championship winning team in 2015.",
        img: "images/coaches/BrandonBrown.png",
        alt: "brandon_brown",
      },
      {
        id: 3,
        name: "Campbell Colpitts",
        description:
        "Campbell is the Head Coach of the BNS U15 Girls team, and has been coaching athletes from youth to university since his playing career ended. He played for the MSVU Mystics and was a teammate of Andrew’s on the BNS National Championship winning team in 2015.",
        img: "images/coaches/CampbellColpitts.png",
        alt: "campbell_colpitts",
      },
      {
        id: 4,
        name: "Nate Darling",
        description:
        "Nate is the first Nova Scotian to play in the NBA – spending time with the Charlotte Hornets and Los Angeles Clippers. He has also represented Team Canada, winning a World Championship at the FIBA U19 World Cup. As a teammate of Andrew, Nate won gold at Nationals in 2015.",
        img: "images/coaches/NateDarling.png",
        alt: "nate_darling",
      },
      {
        id: 5,
        name: "Katie Upham",
        description:
        "Katie played for the StFX X-Women and Calgary Dinos, where she was a teammate of Andrew’s in their program.",
        img: "images/coaches/KatieUpham.png",
        alt: "katie_upham",
      },
      {
        id: 6,
        name: "Alex Carson",
        description:
        "Alex became one of the most decorated players in Canadian university basketball history during his playing career with the Dalhousie Tigers. Off the court, he was recognized as a Top 8 Academic All-Canadian in 2023. Alex was a teammate of Andrew's throughout his years playing for BNS, including on the 2015 National Championship winning team.",        img: "images/coaches/AlexCarson.png",
        img: "images/coaches/AlexCarson.png",
        alt: "alex_carson",
      },
      {
        id: 7,
        name: "Alex Muise",
        description:
        "Alex played for the Acadia Axemen and the Ottawa Gee-Gees. He is a 2x National Champion with BNS, playing with Andrew during both championship runs.",
        img: "images/coaches/AlexMuise.png",
        alt: "alex_muise",
      },
      {
        id: 8,
        name: "Eli Kraushar",
        description:
        "Eli Kraushar played for the Acadia Axemen and is a 2x National Champion with BNS, including being named a First-Team All-Star at Nationals in 2016. He played with Andrew during both championship runs.",
        img: "images/coaches/EliKraushar.png",
        alt: "eli_kraushar",
      },
      {
        id: 9,
        name: "Chloe Oxner",
        description:
        "Chloe is the Head Coach of the Thunder Selects Basketball U16 Elite girls team. She played for the MSVU Mystics and UKC Blue Devils during her playing career.",
        img: "images/coaches/ChloeOxner.png",
        alt: "chloe_oxner",
      },
      {
        id: 10,
        name: "Haley McDonald",
        description:
        "Haley is the Head Coach of the BNS U17 Girls team. She is also an assistant coach with the Acadia Axewomen, after a decorated playing career as an Axewomen highlighted by being named a First Team All-Canadian.",
        img: "images/coaches/HaleyMcDonald.png",
        alt: "haley_mcdonald",
      },
      {
        id: 11,
        name: "Jacob Sheffar",
        description:
        "Jacob played for the MSVU Mystics and grew up playing with and against Andrew, including on BNS Provincial Teams.",
        img: "images/coaches/JacobSheffar.png",
        alt: "jacob_sheffar",
      },
      {
        id: 12,
        name: "Lydell Husbands-Browne",
        description:
        "Lydell is currently playing for the Dalhousie Tigers and attended the first annual 4AM Camp as an athlete.",
        img: "images/coaches/LydellHusbandsBrowne.png",
        alt: "lydell_husbandsbrowne",
      },
      {
        id: 13,
        name: "Rowan Power",
        description:
        "Rowan played for the Acadia Axemen. He was a teammate of Andrew’s on the BNS National Championship winning team in 2015.",
        img: "images/coaches/RowanPower.png",
        alt: "rowan_power",
      },
      {
        id: 14,
        name: "Jayden Falls",
        description:
        "Jayden played for the UPEI Panthers and Holland College Hurricanes. He was a teammate of Andrew’s on the BNS National Championship winning team in 2016.",
        img: "images/coaches/JaydenFalls.png",
        alt: "jayden_falls",
      },
      {
        id: 15,
        name: "Sophia Widmeyer",
        description:
        "Sophia played for the SMU Huskies and grew up as a member of the BNS Provincial Team program alongside Andrew.",
        img: "images/coaches/SophiaWidmeyer.png",
        alt: "sophia_widmeyer",
      },
      {
        id: 16,
        name: "Will Spaulding",
        description:
        "Will played for the SMU Huskies and was a teammate of Andrew’s on the BNS National Championship winning team in 2016. He also boarded at Rothesay Netherwood School with Andrew, where together they helped build the program into what it is today.",
        img: "images/coaches/WillSpaulding.png",
        alt: "will_spaulding",
      },
      {
        id: 17,
        name: "Jayda Veinot",
        description:
        "Jayda is an Assistant Coach with the BNB U17 Girls team after playing for the UNB Reds and the Acadia Axewomen during the course of her playing career, which concluded with her being named the USports Player of the Year.",
        img: "images/coaches/JaydaVeinot.png",
        alt: "jayda_veinot",
      },
      {
        id: 18,
        name: "Dawson Berze-Butts",
        description:
        "Dawson is an MABO certified referee. He played for the MSVU Mystics and was a teammate of Andrew’s on the BNS National Championship winning team in 2016.",
        img: "images/coaches/DawsonBerzeButts.png",
        alt: "dawson_berzebutts",
      },
      {
        id: 19,
        name: "Will Colpitts",
        description:
        "Will played for the UKC Blue Devils. He was teammate of Andrew’s on the BNS National Championship winning team in 2015.",
        img: "images/coaches/WillColpitts.png",
        alt: "will_colpitts",
      },
      {
        id: 20,
        name: "Keevan Veinot",
        description:
        "Keevan is a professional basketball player, playing for the Antwerp Giants of the BNXT League. An accomplished Dalhousie Tiger, he is a former First Team All-Canadian while completing a Commerce degree. Keevan has been a teammate of Andrew's on several different teams.",
        img: "images/coaches/KeevanVeinot.png",
        alt: "keevan_veinot",
      },
      {
        id: 21,
        name: "Jack Campbell",
        description:
        "Jack played for the Acadia Axemen and was a teammate of Andrew’s on the BNS National Championship winning team in 2015.",
        img: "images/coaches/JackCampbell.png",
        alt: "jack_campbell",
      },
      {
        id: 22,
        name: "Kate Gallant",
        description:
        "Kate has been an assistant coach with BNS and played for the MSVU Mystics.",
        img: "images/coaches/KateGallant.png",
        alt: "kate_gallant",
      },
      {
        id: 23,
        name: "Tristan Hyde",
        description:
        "Tristan played for the SMU Huskies. He was teammate of Andrew’s on the BNS National Championship winning team in 2015.",
        img: "images/coaches/TristanHyde.png",
        alt: "tristan_hyde",
      },
      {
        id: 24,
        name: "Vernelle Johnson",
        description:
        "Vernelle is an assistant coach with the Halifax Grammar School Senior Boys team after his playing career with the UPEI Panthers. He was a teammate of Andrew’s on the BNS National Championship winning team in 2015.",
        img: "images/coaches/VernelleJohnson.png",
        alt: "vernelle_johnson",
      },
      {
        id: 25,
        name: "Ben Stevens",
        description:
        "Ben is a professional basketball player, playing for the NH Ostrava in the Czech Republic. He played for the Laurier Golden Hawks and was a teammate of Andrew’s on the BNS National Championship winning team in 2016.",
        img: "images/coaches/BenStevens.png",
        alt: "ben_stevens",
      },
      {
        id: 26,
        name: "Lyon Mclean",
        description:
        "Lyon played for the SMU Huskies and boarded with Andrew at Rothesay Netherwood School.", 
        img: "images/coaches/LyonMclean.png",
        alt: "Lyon Mclean",
      },
      {
        id: 27,
        name: "Sophie Milner",
        description:
        "Sophie currently plays for the Nipissing Lakers. She is Andrew’s younger sister.",
        img: "images/coaches/SophieMilner.png",
        alt: "sophie_milner",
      },
      {
        id: 28,
        name: "Antonio Kostakos",
        description:
        "Antonio is an assistant coach with the Halifax Grammar School Senior boys team after his playing career with the Holland College Hurricanes.",
        img: "images/coaches/AntonioKostakos.png",
        alt: "antonio_kostakos",
      },
      {
        id: 29,
        name: "Courtney Donaldson",
        description:
        "Courtney played for the SMU Huskies and Calgary Dinos, where she was a teammate of Andrew’s in their program.",
        img: "images/coaches/CourtneyDonaldson.png",
        alt: "courtney_donaldson",
      },
      {
        id: 30,
        name: "Caleb Sooley",
        description:
        "Caleb played for the Dalhousie Tigers and boarded with Andrew at Rothesay Netherwood School. He was named the first ever recipient of the Andrew Milner Leadership Award in his senior season.",
        img: "images/coaches/CalebSooley.png",
        alt: "caleb_sooley",
      },
];

export const guestCoaches = [
      {
        id: 31,
        name: "Danny DePalma",
        description:
        "Coach DePalma is the Head Coach of the Men’s Team at MSVU and spent several seasons on the bench with both the men’s and women’s teams at Acadia University. He coached Andrew with BNS, where they won a National Championship in 2016.",
        img: "images/coaches/DannyDePalma.png",
        alt: "danny_palma",
      },
      {
        id: 32,
        name: "Damian Gay",
        description:
        "Coach Gay is the Head Coach at Rothesay Netherwood School. He coached Andrew during Andrew's time at RNS, where the two have been instrumental in the growth of the program.",
        img: "images/coaches/DamianGay.png",
        alt: "damian_gay",
      },
];