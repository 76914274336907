export const homeObjOne = {
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'The first ever recipient of the 4AM Award',
    headline: 'Coby Tunnicliff',
    description:
      "Coby played for Riverview High School in Cape Breton. The $4000 award helped fund his summer of basketball on the BNS U17 Team. We could not be more proud to have Coby as the first ever recipient of the award - he's a phenomenal basketball player, but more importantly, his character embodies Andrew's. Coby travelled from Cape Breton to attend the 2nd Annual 4AM Camp.",
    buttonLabel: 'Get Started',
    imgStart: '',
    img: 'images/Scholarship-CobyTunnicliff.jpg',
    alt: 'coby_tunnicliff'
  };