export const DropdownData = [
  {
    title: "About",
    path: "#",

    subNav: [
      {
        title: "Mission",
        path: "/why",
      },
      {
        title: "Team",
        path: "/team",
      },
      {
        title: "Award",
        path: "/scholarship",
      },
      {
        title: "Media",
        path: "/media",
      },
    ],
  },
  {
    title: "Register",
    path: "/register",

    subNav: [],
  },
  {
    title: "Donate",
    path: "/donate",

    subNav: [],
  },
  {
    title: "Cart",
    path: "/cart",

    subNav: [],
  },
];
